<template>
  <tr class="tr item-tr">
    <td class="td item-td __name">
      <div class="item-tr-name">
        <UserAvatar :user="log.user" :size="`44px`" />
        <span>{{ log.user.artisticName || log.user.name }}</span>
      </div>
    </td>
    <td class="td item-td __count">{{ log.count + " Veces" }}</td>
  </tr>
</template>

<script>
export default {
  props: ["log"],
};
</script>

<style lang="scss" scoped>
.item-tr {
  &-name {
    display: flex;
    align-items: center;
  }
  figure.user-avatar {
    margin: 0 $mpadding 0 0;
  }
}
</style>
